import { createTheme } from '@material-ui/core'

export const BORDER_RADIUS = 0
export const BUTTON_SIZE_RAW = 0.75
export const BUTTON_FONT_SIZE_DEFAULT = `${BUTTON_SIZE_RAW}rem`

export const breakPoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export const colors = {
  // Backgrounds
  BG100: '#FFFFFF', // White
  BG200: '#FEFEFE', // Header
  BG250: '#FBFBFC',
  BG300: '#F8F8FA', // Background
  BG400: '#F1F0F5', // Dark setion

  // Texts
  INK300: '#DDD',    // Disabled button background
  INK400: '#C4BFCF', // Sections
  INK500: '#A8A1B0', // Body light and labels
  INK600: '#CCC',
  INK900: '#000000', // Headers and body

  // Horizontal line
  LINE: '#7263B915',

  // Buttons and borders
  PRI200: '#F1D6DA',
  PRI400: '#E0ADB4', // Bordered buttons
  PRI800: '#A74C75', // Text buttons
  PRI900: '#8C3C60', // Rect buttons gradient colour 1
  PRI910: '#A43E61', // Rect buttons gradient colour 2

  // Card statuses
  LILAC700: '#9585B5',
  LILAC400: '#D8D0E8', // Statuses
  RED400: '#DE838C',
  GREEN400: '#336a5d',
  BLUE400: '#93BEDB',

  PEACH100: '#F8E0C6',
  PEACH200: '#F9F1F0',
  PEACH300: '#DEB0B0',
  PEACH400: '#FCD6C7',
  PEACH500: '#E5ADA6',
  PEACH600: '#F4E3C9',
  PEACH700: '#ED8484',
  PEACH800: '#F1A08B',
  PEACH900: '#F3C5B0',

  PURPLE700: '#9A7B91', // Basket number
  PURPLE800: '#7B74F8',

  BOLD_SHADOW: '#907CC740',
  CARD_SHADOW: '#653ED310',
  INPUT_SHADOW: '#7B5ED020',

  // Destructive buttons
  RED700: '#c66066', // Destructive buttons

  // Stars review
  YELLOW400: '#e4bf6a',

  // Cross Sell
  ORANGE400: '#FAC1A0',
  ORANGE500: '#F7C09A',

  // Alpha backgrounds
  RGBA70_BG100: '#FFFFFFB3',

  // Tips row
  GRAY_800: '#C0CBC9',

  BLACK900: '#000000',
  BLACK910: '#1A1A1A',
  GREY_SLATE: '#807190',

  STONE200: '#EFEBE6',
  STONE400: '#D7CFC7',
}

export const gradients = {
  POSTCODE_POPUP: 'linear-gradient(45deg, #FAEBE8, #F2D2C9)',
}

// This is needed in order to access internal MUI variables (i.e. spacing)
const defaultTheme = createTheme(
  {
    spacing: 6,
  },
)

export const theme = createTheme({
  spacing: defaultTheme.spacing,
  typography: {
    fontFamily: 'Muli, sans-serif',
    h1: {
      fontFamily: 'quiche-sans',
      color: colors.INK900,
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'quiche-sans',
      color: colors.INK900,
      fontWeight: 500,
    },
    h3: {
      fontFamily: 'quiche-sans',
      color: colors.INK900,
      fontWeight: 500,
      fontSize: '2.2rem',
    },
    h4: {
      fontFamily: 'quiche-sans',
      color: colors.INK900,
      fontSize: '1.5rem',
      lineHeight: 1.24,
      fontWeight: 500,
    },
    h5: {
      fontFamily: 'quiche-sans',
      color: colors.INK900,
      fontSize: '1.25rem',
      lineHeight: 1,
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 200,
      fontSize: '1.1rem',
      color: colors.INK900,
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: colors.INK900,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 200,
      lineHeight: 1.5,
      color: colors.INK900,
    },
    body2: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: 1.5,
      color: colors.INK900,
    },
    caption: {
      fontSize: '0.9rem',
      fontWeight: 200,
      lineHeight: 1.35,
      color: colors.INK500,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 800,
      letterSpacing: 2,
      color: colors.INK400,
    },
    button: {
      fontSize: BUTTON_FONT_SIZE_DEFAULT,
      fontWeight: 'bold',
      lineHeight: 1.5,
      color: colors.INK900,
      letterSpacing: 2,
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      main: colors.BLACK900,
    },
    secondary: {
      main: colors.BG100,
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: BORDER_RADIUS,
        background: colors.BG200,
      },
    },
    MuiCard: {
      root: {
        padding: defaultTheme.spacing(2),
        margin: defaultTheme.spacing(2),
        position: 'relative',
        borderRadius: BORDER_RADIUS,
        background: colors.BG200,
        elevation: 0,
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        margin: 0,

        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
        marginTop: defaultTheme.spacing(2),
      },
    },
    MuiDialogActions: {
      root: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: defaultTheme.spacing(3, 4),
        backgroundColor: colors.BG100,
        zIndex: 100,
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: colors.BG100,
        padding: 0,
        zIndex: 50,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: defaultTheme.spacing(3, 4),
        zIndex: 100,
        backgroundColor: colors.BG100,
        width: '100%',
        boxSizing: 'border-box',
        '&.hero': {
          paddingTop: defaultTheme.spacing(20),
        },
        borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      },
    },
    MuiTextField: {
      root: {
        marginTop: 0,
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: colors.BG300,
        padding: defaultTheme.spacing(1.5, 2),
        borderRadius: BORDER_RADIUS,
        marginTop: defaultTheme.spacing(0),
        border: '1px solid rgba(0, 0, 0, 0.08)',
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.BLACK900,
      },
    },
    MuiSelect: {
      root: {
        padding: '0 !important',
      },
      selectMenu: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      select: {
        padding: '15px 24px 15px 32px !important',
        textAlign: 'center',
        textTransform: 'capitalize',
        boxSizing: 'border-box',
        width: '100%',
      },
      icon: {
        left: defaultTheme.spacing(1),
        right: 'auto',
        color: colors.BLACK900,
      },
    },
    MuiListItem: {
      gutters: {
        padding: defaultTheme.spacing(4),
      },
      root: {
        borderBottom: `1px solid ${colors.BG400}`,
      },
    },
    MuiMenuItem: {
      root: {
        height: defaultTheme.spacing(8),
        padding: defaultTheme.spacing(4),
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
    },
  },
})

export const stripeInputStyle = {
  base: {
    fontFamily: 'Muli, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 200,
    color: colors.INK900,
    '::placeholder': {
      color: colors.INK400,
    },
  },
}

export const stripeContainerStyle = {
  backgroundColor: colors.BG100,
  padding: defaultTheme.spacing(3),
  border: 0,
  borderRadius: BORDER_RADIUS,
  height: theme.spacing(3),
}

export type Colors = typeof colors
export type Gradients = typeof gradients
export type Breakpoints = typeof breakPoints