import * as Backgrounds from '../public/img/categories/'
import * as Icons from '../public/img/navMenuIcons/'

const categories = {
  default: {
    icon: Icons.Packages,
    textColor: '#EFEAED',
    color: '#d59d96',
    imageName: 'nails',
  },
  nails: {
    backgroundImage: Backgrounds.Nails,
    sqImage: Backgrounds.sqNails,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left 30%',
    icon: Icons.Nails,
    textColor: 'white',
    color: '#d59d96',
    imageName: 'nails',
  },
  facial: {
    backgroundImage: Backgrounds.Facial,
    sqImage: Backgrounds.sqFacial,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Facial,
    color: '#c98a71',
    imageName: 'facial',
  },
  eyes: {
    backgroundImage: Backgrounds.Eyes,
    sqImage: Backgrounds.sqEyes,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Eyes,
    color: '#b8997d',
    imageName: 'eyes',
  },
  make_up: {
    backgroundImage: Backgrounds.Makeup,
    sqImage: Backgrounds.sqMakeup,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Makeup,
    color: '#745d67',
    imageName: 'makeup',
  },
  massage: {
    backgroundImage: Backgrounds.Massage,
    sqImage: Backgrounds.sqMassage,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left 60%',
    textColor: 'white',
    icon: Icons.Massage,
    color: '#7b5d6b',
    imageName: 'massage',
  },
  waxing: {
    backgroundImage: Backgrounds.Waxing,
    sqImage: Backgrounds.sqWaxing,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left',
    textColor: 'white',
    icon: Icons.Waxing,
    color: '#99a3a2',
    imageName: 'waxing',
  },
  spray_tan: {
    backgroundImage: Backgrounds.Spraytan,
    sqImage: Backgrounds.sqSpraytan,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Spraytan,
    color: '#b4927e',
    imageName: 'spraytan',
  },
  hair: {
    backgroundImage: Backgrounds.Hair,
    sqImage: Backgrounds.sqHair,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Hair,
    color: '#a0a5a1',
    imageName: 'hair',
  },
  hairdressing: {
    backgroundImage: Backgrounds.Hairdressing,
    sqImage: Backgrounds.sqHair,
    backgroundImagePositionLarge: 'center bottom',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Hairdressing,
    color: '#a0a5a1',
    imageName: 'hair',
  },
  iv_drips: {
    backgroundImage: Backgrounds.Ivdrips,
    sqImage: Backgrounds.sqIvdrips,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center left 20%',
    textColor: 'white',
    icon: Icons.Ivdrips,
    color: '#d2a363',
    imageName: 'ivdrips',
  },
  packages: {
    backgroundImage: Backgrounds.Packages,
    sqImage: Backgrounds.sqPackages,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Packages,
    color: '#400d15',
    imageName: 'packages',
  },
  aesthetics: {
    backgroundImage: Backgrounds.Aesthetics,
    sqImage: Backgrounds.sqAesthetics,
    backgroundImagePositionLarge: 'center center',
    backgroundImagePositionSmall: 'center right',
    textColor: 'white',
    icon: Icons.Aesthetics,
    color: '#a9b7b2',
    imageName: 'aesthetics',
  },
}

export default categories