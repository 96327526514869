import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Typography, BoxProps, createStyles, withStyles, WithStyles } from '@material-ui/core'
import Image from 'next/image'
import RootStore from '../src/stores/RootStore'
import { $BookingModel } from '../shared-consumer/stores/BookingStore/BookingModel'
import ImageDialog from './ImageDialog'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import { EditSharp } from '@material-ui/icons'
import RectButton from './RectButton'
import { theme } from '../theme'
import PhotosIcon from '../public/img/icons/photos.png'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
  booking: $BookingModel
  editable?: boolean
  style?: React.CSSProperties
  boxProps: BoxProps
}

const styles = () => createStyles({
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
})

@inject('store')
@observer
class InstructionsView extends Component<Props> {
  showInstructionsPopup = () => {
    const { modalController } = this.props.store!
    modalController.openModal('bookingInstructions')
  }

  showImageDialog = () => {
    const { uiStore } = this.props.store!
    uiStore.showImageDialog()
  }

  render() {
    const { booking, store, editable = false, boxProps, style, classes } = this.props
    const { i18n: { s, pluralise }, authStore } = store!
    const { data: { clientNote, clientPictures, items } } = booking
    const hasInstructions = clientPictures.length > 0 || clientNote !== ''
    const images = clientPictures.map((cp: string) => { return `${process.env.IMAGE_LOCATION}${cp}` })

    if (items.length === 0 || !authStore.isLoggedIn) {
      return null
    } else {
      return !hasInstructions ? (
        <Box style={style} {...boxProps}>
          {editable && <RectButton
            variant='text'
            onClick={this.showInstructionsPopup}
            startIcon={<Image src={PhotosIcon} alt={s('BOOKING_INSTRUCTIONS_BUTTON')} width={theme.spacing(3)} height={theme.spacing(3)} priority />}
          >
            {s('BOOKING_INSTRUCTIONS_BUTTON')}
          </RectButton>}
        </Box>
      ) : (
        <Box style={style} {...boxProps}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Typography variant='body2'>{s('BOOKING_INSTRUCTIONS_LABEL')}</Typography>
            {editable &&
              <RectButton
                variant='text'
                onClick={this.showInstructionsPopup}
                endIcon={<EditSharp />}
              >
                {s('Edit')}
              </RectButton>
            }
          </Box>
          <Typography variant='caption' className={classes.truncateText}>{clientNote}</Typography>
          {clientPictures.length > 0 &&
            <Box display='flex' flexDirection='row' alignItems='center' gridGap={theme.spacing(1)} onClick={this.showImageDialog}>
              <ImageIcon color='disabled' />
              <Typography variant='overline'>{`${clientPictures.length} ${pluralise('photo', clientPictures.length)}`}</Typography>
            </Box>}
          <ImageDialog images={images} />
        </Box>
      )
    }
  }
}

export default withStyles(styles)(InstructionsView)