import { action, observable } from 'mobx'
import RootStore from '../RootStore'
import Errors from '../../../shared-consumer/languages/en_errors'
import { FormattedError } from '../../../shared-lib/communication/ErrorHelper'

// TODO: Move into i18n
const errorStrings = {
  GENERIC: 'Something went wrong',
  NO_INTERNET_ERROR: 'No internet connection',
  MALFORMED_RESPONSE_ERROR: 'Server error',
  SERVER_CONNECTION_ERROR: 'Server error',
}

export interface $DialogButton {
  title: string
  onPress?: any
  preventModalClose?: boolean
}

interface $Dialog {
  title?: string
  body?: string
  buttons?: Array<$DialogButton>
  important?: boolean
}

export interface $InfoModalData {
  visible?: boolean
  title: string
  body: string
  isMarkdown?: boolean
}

export default class UIStore {
  rootStore: RootStore
  @observable dialog: $Dialog = {}
  @observable proQualityVisible = false
  @observable cartVisible = false
  @observable navVisible = false
  @observable dialogVisible = false
  @observable cookieBannerVisible = true
  @observable postCodeDialogVisible = false
  @observable isUnreadMessageCardVisible = false // Unused in WB, only in CA
  @observable rebookPopupVisible = false
  @observable imageDialogVisible = false
  @observable reviewBookingId = ''
  @observable tipBookingId = ''
  @observable informationModalData: any = {}
  seenQualityModal = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  // Create alert data with title and body for a given array of errors
  @action.bound handleErrors(error: Pick<FormattedError, 'code'>): void | boolean {
    const { authStore, i18n: { s } } = this.rootStore

    if (authStore.sessionExpired) return

    try {
      const { code } = error

      const title = code in errorStrings ? errorStrings[code as keyof typeof errorStrings] : errorStrings.GENERIC
      const body = code in Errors ? Errors[code as keyof typeof Errors] as string : s('An unknown error occurred.')

      const showSupportButton = Errors.SUPPORT.indexOf(code) > -1
      const important = ['NO_INTERNET_ERROR', 'SERVER_CONNECTION_ERROR', 'MALFORMED_RESPONSE_ERROR'].includes(code)

      const buttons = []
      if (showSupportButton) buttons.push({ title: s('Contact us'), onPress: this.contactSupport })

      buttons.push({ title: s('Close') })

      this.showMessage({ title, body, buttons, important })
    } catch (e) {
      this.showMessage({ title: s('Something went wrong'), body: s('An unknown error occurred.') })
    }
  }

  // Contact support
  @action.bound contactSupport(): void {
    const { modalController } = this.rootStore
    modalController.openModal('support')
  }

  // Toggle cart visible
  @action.bound toggleCartVisible(visible: boolean) {
    this.cartVisible = visible
  }

  // Toggle nav visible
  @action.bound toggleNavVisible(visible: boolean) {
    this.navVisible = visible
  }

  // Show Dialog
  @action.bound showMessage(dialog: $Dialog): void {
    if (!this.dialog.important || dialog.important) {
      this.dialog = dialog
    }
  }

  @action.bound closeMessage(): void {
    this.dialog = {}
  }

  @action.bound showInformationModal(message: $InfoModalData): void {
    this.informationModalData = {
      visible: true,
      ...message,
    }
  }

  @action.bound hideInformationModal(): void {
    this.informationModalData = {
      ...this.informationModalData,
      visible: false,
    }
  }

  // Show Postcode Dialog
  @action.bound showPostCodeDialog(): void {
    this.postCodeDialogVisible = true
  }

  // Show Cookie banner
  @action.bound hideCookieBanner(): void {
    this.cookieBannerVisible = false
    this.syncInStorage()
  }

  // Show pro quality modal
  @action.bound showQualityModal(): void {
    this.proQualityVisible = true
  }

  @action.bound hideQualityModal(): void {
    this.proQualityVisible = false
    this.seenQualityModal = true
  }

  // Open rating popup
  @action.bound showReviewPopup(_id: string): void {
    this.reviewBookingId = _id
  }

  // Open tip popup
  @action.bound tipProfessional(_id: string): void {
    this.tipBookingId = _id
  }

  @action.bound showRebookPopup(): void {
    this.rebookPopupVisible = true
  }

  @action.bound hideRebookPopup(): void {
    this.rebookPopupVisible = false
  }

  // Show Image Dialog
  @action.bound showImageDialog(): void {
    this.imageDialogVisible = true
  }

  showPostcodeOutsideServiceAreaPopup = () => {
    const { uiStore, i18n: { s } } = this.rootStore

    // Show popup
    uiStore.showMessage({
      title: s('POSTCODE_OUTSIDE_AREA_TITLE'),
      body: s('POSTCODE_OUTSIDE_AREA_BODY'),
      buttons: [
        {
          title: s('Close'),
        },
      ],
    })
  }

  showInvalidPostcodePopup = () => {
    const { uiStore, i18n: { s } } = this.rootStore

    // Show popup
    uiStore.showMessage({
      title: s('INVALID_POSTCODE_TITLE'),
      body: s('INVALID_POSTCODE_BODY'),
      buttons: [
        {
          title: s('Close'),
        },
      ],
    })
  }

  showOuterCodeChangedPopup = (callback: any) => {
    const { i18n: { s } } = this.rootStore

    this.showMessage({
      title: s('POSTCODE_CHANGED_TITLE'),
      body: s('POSTCODE_CHANGED_BODY'),
      buttons: [
        {
          title: s('Continue'),
          onPress: callback,
        },
        {
          title: s('Cancel'),
        },
      ],
    })
  }

  showRegionChangedPopup = (callback: any) => {
    const { i18n: { s } } = this.rootStore

    this.showMessage({
      title: s('REGION_CHANGED_TITLE'),
      body: s('REGION_CHANGED_BODY'),
      buttons: [
        {
          title: s('Continue'),
          onPress: callback,
        },
        {
          title: s('Cancel'),
        },
      ],
    })
  }

  showCheckoutErrorPopup = (callback: any) => {
    const { i18n: { s } } = this.rootStore

    this.showMessage({
      title: s('BOOKING_VALIDATION_ERROR_TITLE'),
      body: s('BOOKING_VALIDATION_ERROR_BODY'),
      buttons: [
        {
          title: s('Continue'),
          onPress: callback,
        },
      ],
    })
  }

  showInvalidBookingTimePopup = (callback: any) => {
    const { i18n: { s } } = this.rootStore

    this.showMessage({
      title: s('BOOKING_INVALID_TIME_TITLE'),
      body: s('BOOKING_INVALID_TIME_BODY'),
      buttons: [
        {
          title: s('Continue'),
          onPress: callback,
        },
      ],
    })
  }

  showRemoveProfessionalPopup = () => {
    const { uiStore, checkoutStore, i18n: { s } } = this.rootStore

    uiStore.showMessage({
      title: s('Are you sure?'),
      body: s('CONFIRM_REMOVE_PRO'),
      buttons: [
        {
          title: s('Remove'),
          onPress: checkoutStore.removeProvisionalProfessionalFromBooking,
        },
        {
          title: s('Cancel'),
        },
      ],
    })
  }

  showLoginRequiredModal = () => {
    const { uiStore, modalController, i18n: { s } } = this.rootStore

    uiStore.showMessage({
      title: s('Login required'),
      body: s('You must be logged in to your Secret Spa account to view this screen.'),
      buttons: [
        {
          title: s('Create an account'),
          onPress: () => {
            modalController.openModal('auth', 'create-account')
          },
        },
        {
          title: s('Sign in to existing account'),
          onPress: () => {
            modalController.openModal('auth', 'sign-in')
          },
        },
        {
          title: s('Close'),
        },
      ],
    })
  }

  // Add value to a variable
  @action.bound inflate(data: any) {
    Object.keys(data).forEach(key => {
      this[key as keyof typeof this] = data[key]
    })
  }

  // Sync store data
  @action.bound syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('UI')
  }

  clearData() {
    this.cookieBannerVisible = true
    this.syncInStorage()
  }

  // Use when we want to save to storage
  serialize() {
    return {
      cookieBannerVisible: this.cookieBannerVisible,
    }
  }
}
