import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Box, Divider, Typography } from '@material-ui/core'
import { Avatar, Clickable, RectButton } from '../'
import RootStore from '../../src/stores/RootStore'
import { theme } from '../../theme'
import { CalendarTodaySharp, ChatSharp, Search } from '@material-ui/icons'

interface Props {
  store?: RootStore
}

@inject('store')
@observer
class AccountActions extends Component<Props> {
  login = () => {
    const { modalController, uiStore, routeHandler } = this.props.store!
    uiStore.navVisible = false
    routeHandler.postAuthenticationAction = undefined
    modalController.openModal('auth', 'sign-in')
  }

  createAccount = () => {
    const { modalController, uiStore, routeHandler } = this.props.store!
    uiStore.navVisible = false
    routeHandler.postAuthenticationAction = undefined
    modalController.openModal('auth', 'create-account')
  }

  openAccount = () => {
    const { modalController, uiStore } = this.props.store!
    uiStore.navVisible = false
    modalController.openModal('account')
  }

  openBookings = () => {
    const { modalController, uiStore } = this.props.store!
    uiStore.navVisible = false
    modalController.openModal('bookings')
  }

  openChats = () => {
    const { modalController, uiStore } = this.props.store!
    uiStore.navVisible = false
    modalController.openModal('chats')
  }

  openSearch = () => {
    const { checkoutStore: { hasPostcode }, uiStore, modalController } = this.props.store!

    // Check if user has a postcode or addresses store under account
    if (!hasPostcode) {
      uiStore.showPostCodeDialog()
    } else {
      modalController.openModal('professionalSearch', 'new')
    }
  }

  render() {
    const { i18n: { s }, authStore, userStore, chatStore } = this.props.store!
    const { isLoggedIn } = authStore
    const { data, profilePictureUpdate } = userStore
    const { login, createAccount, openAccount, openSearch, openBookings, openChats } = this
    const chatText = !!chatStore.unreadMessages ? s('Chats ($0)', chatStore.unreadMessages) : s('Chats')

    return (
      <>
        {isLoggedIn && data._id
          ? <Clickable onClick={openAccount}>
            <Box display='flex' flexDirection='row' mt={3} alignItems='center' aria-label='open_account_modal'>
              <Avatar user={data} size={16} isUser refetch={profilePictureUpdate} />
              <Box mr={1.5} />
              <Typography variant='button'>{s('Account')}</Typography>
            </Box>
          </Clickable>
          : <Box display='flex' flexDirection='row' mt={3} alignItems='center' gridGap={theme.spacing(2)}>
            <RectButton
              fullWidth
              onClick={login}
              size={'medium'}
              variant='outlined'
            >{s('Login')}
            </RectButton>

            <RectButton
              fullWidth
              onClick={createAccount}
            >{s('Register')}
            </RectButton>
          </Box>
        }

        {isLoggedIn && <Box mt={3}>
          <RectButton
            onClick={openBookings}
            variant='text'
            startIcon={<CalendarTodaySharp />}
          >
            {s('Bookings')}
          </RectButton>
        </Box>}

        {isLoggedIn && <Box mt={3}>
          <RectButton
            onClick={openChats}
            variant='text'
            startIcon={<ChatSharp />}
          >
            {chatText}
          </RectButton>
        </Box>}

        <Box mt={4} mb={3}>
          <Divider light />
        </Box>

        <RectButton
          onClick={openSearch}
          variant='text'
          startIcon={<Search />}
        >
          {s('PROFESSIONAL_DISPLAY_NAME_PLURAL')}
        </RectButton>

        <Box mt={3} mb={0}>
          <Divider light />
        </Box>
      </>
    )
  }
}

export default AccountActions
