import { isWidthDown, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import { inject, observer } from 'mobx-react'
import dynamic from 'next/dynamic'
import React from 'react'

import { SplashScreen } from '../components'
import ZendeskMessageAlert from '../components/ZendeskMessageAlert'
import RootStore from '../src/stores/RootStore'

import ModalScreens from '../modals/ModalScreens'
import DropDownMessage from '../components/DropdownMessage'

const CheckoutDesktopLayoutFullScreen = dynamic(() => import('../components/layouts/CheckoutDesktopLayoutFullScreen'))
const CheckoutDesktopLayout = dynamic(() => import('../components/layouts/CheckoutDesktopLayout'))
const CheckoutMobileLayout = dynamic(() => import('../components/layouts/CheckoutMobileLayout'))
const BrowseLayout = dynamic(() => import('../components/layouts/BrowseLayout'))
const TestUI = dynamic(() => import('../components/Testing/TestUI'))

@inject('store')
@observer
class MainView extends React.Component<{ store?: RootStore, width: Breakpoint }> {
  render() {
    const { children, width, store } = this.props
    const { testUiStore: { isTesting } } = store!

    const topPath = store!.router.asPath.split('/')[1].split('?')[0].split('#')[0]
    const isMobile = isWidthDown('sm', width)

    const pageContent = store!.readyStatus === 2 ? <>
      {children}
      <ModalScreens />
      <ZendeskMessageAlert />
      <DropDownMessage />
      {isTesting && <TestUI />}
    </> : null

    if (topPath === 'application') {
      return pageContent
    }

    if (topPath === 'booking') {
      let CheckoutLayout = CheckoutMobileLayout
      if (!isMobile) {
        if (store!.router.asPath.includes('pre-summary')) CheckoutLayout = CheckoutDesktopLayoutFullScreen
        else CheckoutLayout = CheckoutDesktopLayout
      }

      return <CheckoutLayout>
        {pageContent}
      </CheckoutLayout>
    }

    return <>
      <BrowseLayout>
        {pageContent}
        <SplashScreen />
      </BrowseLayout>
    </>
  }
}

export default withWidth()(MainView)
