import React from 'react'

import { Box } from '@material-ui/core'

const logo = {
  height: '100%',
  maxWidth: '280px',
}

class Logo extends React.Component<{ size?: number, inverted?: boolean }> {
  render() {
    const { size = 18, inverted } = this.props

    const style = inverted ? {
      filter: 'invert(1)',
    } : {}

    const onClick = () => {
      window.location.href = 'https://secretspa.co.uk/'
    }

    return <Box onClick={onClick} style={{ cursor: 'pointer' }}>
      <Box height={size} style={style}>
        <img src='/img/logos/secret-spa-logo.svg' alt='Secret Spa' style={logo} />
      </Box>
    </Box>
  }
}

export default Logo