import { abbreviatedUserName } from '../lib/utils'
import { $Professional } from '../stores/ProfessionalStore/ProfessionalModel'

// Get booking status title
export const statusAsString = (status: string): string => {
  const statusTitles = {
    REQUESTED: 'Requesting',
    ACCEPTED: 'Confirmed',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    ABANDONED: 'Abandoned',
    APPROVAL_PENDING: 'Booked',
    PAYMENT_PENDING: 'Pending',
    IN_TRANSIT: 'On the way',
    ARRIVED: 'In progress',
    PAYMENT_FAILED: 'Payment failed',
    UNKNOWN: 'Unknown',
  }
  return (statusTitles[status] || status).toUpperCase()
}

// Get booking status color from status
export const statusColor = (status: string): string => {
  const statusColors = {
    REQUESTED: 'blue',
    APPROVAL_PENDING: 'green',
    ACCEPTED: 'green',
    COMPLETED: 'lilac',
    CANCELLED: 'red',
    ABANDONED: 'red',
    IN_TRANSIT: 'blue',
    ARRIVED: 'blue',
    PAYMENT_PENDING: 'blue',
    PAYMENT_FAILED: 'red',
  }

  return statusColors[status]
}

export const professionalOrStatusStringHelper = (status: string, type: string, professional: $Professional | undefined, hasRequestedProfessionals: boolean, s: any, isMassage?: boolean) => {

  if (type === 'instant' || type === 'reverseAuction') return abbreviatedUserName(professional)

  if (status === 'CANCELLED') return s('Cancelled booking')

  const isAccepted = status !== 'REQUESTED' && status !== 'DRAFT'
  const requestedPro = status !== 'DRAFT' ? professional : undefined

  if (isAccepted) {
    return abbreviatedUserName(requestedPro)
  } else {
    const isSummary = status === 'DRAFT'
    const requestedTitle = s('Requesting availability..')
    const professionalTitle = isSummary ?
      isMassage ?
        s('We will find you a female therapist') :
        s('We will find you a therapist') :
      s('Finding a therapist...')

    const findingTitle = hasRequestedProfessionals
      ? requestedTitle
      : professionalTitle

    return findingTitle
  }
}