import { observable } from 'mobx'
import React from 'react'

const yEdgeCases = new Set(['day'])

class I18n {
  @observable language = ''
  languages: Record<string, Record<string, string>> = {}

  constructor(languages: Record<string, Record<string, string>>, language: string) {
    this.languages = languages
    this.language = language
  }

  setLanguage(language: string) {
    this.language = language
  }

  get currentLanguage() {
    return this.languages[this.language]
  }

  // Method to translate string
  s = (string: string, ...args: (string | number | null | undefined)[]) => {
    // Create lowercase version
    const stringL = string.toLowerCase()

    // Match on string, lowercased string or return initial string passed in
    let str = this.currentLanguage![string] || this.currentLanguage![stringL] || string

    // Do templating
    args.forEach((arg, index) => {
      str = str.replace(new RegExp(`\\$${index}`, 'g'), `${arg}`)
    })

    return str
  }

  pluralise = (string: string, count = 0) => {
    const i18nString = this.s(string)

    if (count === 1) return i18nString

    // For english
    const lastLetter = i18nString.charAt(i18nString.length - 1)
    if (lastLetter === 'y') {
      // y edge cases
      if (yEdgeCases.has(i18nString.toLocaleLowerCase())) {
        return i18nString + 's'
      }

      return i18nString.slice(0, i18nString.length - 1) + 'ies'
    }
    if (lastLetter === 's') return i18nString + 'es'

    return i18nString + 's'
  }
}

export type $i18n = I18n
export default I18n

export const I18nContext = React.createContext({} as I18n)

export const useI18n = () => {
  const i18n = React.useContext(I18nContext)
  if (!i18n) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('i18n must be used within a I18nContext.')
  }
  return i18n
}