import momentTz, { Moment } from 'moment-timezone'

export const calculateAmounts = (cardTotal: number, creditsTotal: number, percentage: number) => {
  const totalPaid = cardTotal + creditsTotal
  const totalRefund = totalPaid * percentage / 100

  const cardRefundAmount = cardTotal <= totalRefund ? cardTotal : cardTotal * percentage / 100

  return { cardRefundAmount, creditsRefundAmount: totalRefund - cardRefundAmount }
}

export const bookingIsEditable = (status: string, date: Moment) => {
  return ['ACCEPTED', 'APPROVAL_PENDING'].includes(status)
    && momentTz().add(24, 'hours').isBefore(date)
}