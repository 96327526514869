import Link from 'next/link'
import React from 'react'

import { Button, CircularProgress, Size, WithStyles, styled, withStyles } from '@material-ui/core'
import { BORDER_RADIUS, BUTTON_FONT_SIZE_DEFAULT, colors, theme } from '../theme'
import { snakeCase } from 'voca'
import { styleCreate } from '../lib/styleCreate'

const StyledButton = styled(Button)({
  border: 0,
  borderRadius: BORDER_RADIUS,
  height: theme.spacing(9),
  padding: '0 30px',
  textTransform: 'uppercase',
  fontWeight: 200,
  fontSize: '0.75rem',
  color: `${colors.BG100}`,
  letterSpacing: 2,

  '&.MuiButton-containedSizeSmall': {
    height: theme.spacing(5),
    fontWeight: '700',
    padding: theme.spacing(0, 2),
    fontSize: '0.6rem',
  },
  '&.MuiButton-containedSizeMedium': {
    height: theme.spacing(7),
    fontWeight: '700',
    padding: theme.spacing(0, 2),
    fontSize: '0.6rem',
  },
  '&.MuiButton-outlinedSizeSmall': {
    height: theme.spacing(5),
    fontWeight: '700',
    padding: theme.spacing(0, 2),
    fontSize: '0.6rem',
  },
  '&.MuiButton-outlinedSizeMedium': {
    height: theme.spacing(7),
    fontWeight: '700',
    padding: theme.spacing(0, 2),
    fontSize: '0.6rem',
  },
  '&.MuiButton-containedSecondary': {
    color: colors.BLACK900,
    backgroundColor: colors.BG300,
  },
  '&.MuiButton-containedPrimary': {
    background: colors.BLACK900,
  },
  '&.MuiButton-containedPrimary:hover': {
    background: colors.BLACK910,
  },
  '&.MuiButton-containedSecondary:hover': {
    backgroundColor: colors.BLACK900,
    color: colors.BG100,
  },
  '&:disabled': {
    background: `${colors.INK300} !important`,
    color: colors.BG100,
  },
  '&.MuiButton-outlinedPrimary': {
    color: colors.BLACK900,
    border: `1px solid ${colors.BLACK900}`,
  },
  '&.MuiButton-outlinedSecondary': {
    color: colors.BG100,
    border: `1px solid ${colors.BG100}`,
  },
  '&.MuiButton-textPrimary': {
    color: colors.BLACK900,
    textTransform: 'uppercase',
    fontSize: BUTTON_FONT_SIZE_DEFAULT,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: 2,
    height: 'auto',
    padding: 0,
    '& .MuiButton-startIcon': {
      marginLeft: '0px',
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
    },
    '& .MuiButton-endIcon': {
      marginRight: '0px',
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
    },
  },
  '&.MuiButton-textPrimary:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiButton-textSizeSmall': {
    height: theme.spacing(5),
    fontWeight: '700',
    padding: theme.spacing(0, 2),
    fontSize: '0.6rem',
  },
})

const styles = styleCreate(() => ({
  textIcon: {
    fontSize: 12,
  },
}))

interface Props extends WithStyles<typeof styles> {
  className?: string
  children: any
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  href?: any
  as?: string
  disabled?: boolean
  color?: any
  variant?: 'text' | 'contained' | 'outlined'
  fullWidth?: boolean
  endIcon?: any
  startIcon?: any
  style?: any
  size?: Size
  ariaPrefix?: string
  loading?: boolean
}

const RectButton = (props: Props) => {
  const {
    className = '',
    ariaPrefix = '',
    disabled,
    color = 'primary',
    size,
    children,
    onClick,
    fullWidth,
    variant = 'contained',
    href,
    as,
    endIcon,
    startIcon,
    style,
    loading = false,
  } = props

  const content = loading ? <CircularProgress size={25} /> : children

  const inner = <StyledButton
    className={className}
    disabled={disabled || loading}
    color={color}
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
      // Remove focus from the button on press, otherwise subsequent "enter" key presses will cause another submission
      // @ts-ignore
      e.target.blur && e.target.blur()
      onClick && onClick(e)
    }}
    size={size}
    fullWidth={fullWidth}
    variant={variant}
    endIcon={endIcon}
    startIcon={startIcon}
    style={style}
    aria-label={snakeCase(`${children} ${ariaPrefix} button`)}
    disableRipple={variant === 'text' ? true : false}
  >
    {content}
  </StyledButton>

  return onClick ? inner : <Link href={href} as={as || href}>{inner}</Link>
}

export default withStyles(styles)(RectButton)
