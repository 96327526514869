import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Typography } from '@material-ui/core'

import { Avatar, Clickable, RectButton } from '../'
import { abbreviatedUserName } from '../../shared-consumer/lib/utils'
import RootStore from '../../src/stores/RootStore'
import { Cancel } from '@material-ui/icons'

interface Props {
  store?: RootStore
}

@inject('store')
@observer
class BookingProfessional extends Component<Props> {
  openProfessionalProfile = () => {
    const { modalController, checkoutStore: { provisionalProfessional } } = this.props.store!
    modalController.openModal('professionalProfile', provisionalProfessional._id, 'bookWith')
  }

  render() {
    const { store } = this.props
    const {
      checkoutStore: { provisionalProfessional },
      uiStore: { showRemoveProfessionalPopup },
      i18n: { s },
    } = store!

    return (
      <Box display='flex' mt={3} mb={3} alignItems='center' justifyContent='space-between'>
        <Clickable onClick={this.openProfessionalProfile}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box mr={2}>
              <Avatar user={provisionalProfessional} size={30} />
            </Box>
            <Typography variant='body2'>{abbreviatedUserName(provisionalProfessional)}</Typography>
          </Box>
        </Clickable>
        <RectButton
          variant='text'
          onClick={showRemoveProfessionalPopup}
          endIcon={<Cancel />}
        >{s('Remove')}
        </RectButton>
      </Box>
    )
  }
}

export default BookingProfessional
