/*
 *Common strings shared between all platforms.
 *
 *The system relies on these names so DO NOT change
 *them without considering how the logic they effect
 *will need to be updated.
 */

export const SERVICE_FEE_ITEM_NAME = 'Service Fee'
export const NEW_PROFESSIONAL_DISCOUNT = 'New to Secret Spa discount'
export const OFFPEAK_ITEM_NAME = 'Off-Peak discount'
export const ELITE_TREATMENT_COST = 'Elite Professional Fee'

export const TreatmentTypes = [
  'iv-drips',
  'packages',
  'nails',
  'massage',
  'facial',
  'make-up',
  'spray-tan',
  'hair-styling',
  'hair',
  'hair-cutting',
  'waxing',
  'eyes',
]

export const CityOptions = [
  'london',
  'manchester',
  'brighton',
  'leeds',
  'liverpool',
  'glasgow',
  'edinburgh',
  'other',
]

export const MOBILE_AVAILABILITY_ID = 'mobileavailabilityid'
  .split('')
  .map(c => c.charCodeAt(0).toString(16))
  .join('')
  .slice(0, 24) // 6d6f62696c65617661696c61

export const SSPA_AVAILABILITY_ID = 'sspaavailabilityid'
  .split('')
  .map(c => c.charCodeAt(0).toString(16))
  .join('')
  .slice(0, 24)

export const MIN_BOOKING_AMOUNT = 0
export const MIN_BOOKING_DATE = 4 // hours
export const PACKDOWN_TIME = 0 // minutes
export const TRAVEL_TIME = 30 // minutes
export const DEFAULT_AVAILABILITY_DAY = '3000-01-01'
export const ETERNAL_PROMO_CODE_DAY = '4000-01-01'
