import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, WithStyles, withStyles } from '@material-ui/core'

import RootStore from '../../src/stores/RootStore'
import { colors } from '../../theme'
import { styleCreate } from '../../lib/styleCreate'
import TextButton from '../TextButton'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
  href?: string
  as?: string
  title?: string
  options?: any
  disabled?: boolean
  onPress?: () => void
}

const styles = styleCreate(theme => ({
  img: {
    marginRight: theme.spacing(2),
    width: 30,
    height: 28,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 0, 1, 0),
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
    color: colors.INK900,
    marginTop: 2,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
}))

@inject('store')
@observer
class NavMenuLink extends Component<Props> {
  onClick = () => {
    const { href = '', as, store, options = {}, onPress } = this.props
    const { uiStore: { toggleNavVisible }, router, modalController } = store!

    toggleNavVisible(false)

    if (onPress) {
      onPress()
    } else if (href[0] === '#') {
      modalController.openModalFromHash(href)
    } else {
      router.push(
        href, as, options,
      )
    }
  }

  render() {
    const { classes, title, disabled = false } = this.props
    const disabledClass = disabled ? classes.disabled : ''

    return <Box
      className={`${classes.container} ${disabledClass}`}
      onClick={this.onClick}
    >
      <TextButton onClick={this.onClick}>
        {title}
      </TextButton>
    </Box>
  }
}

export default withStyles(styles)(NavMenuLink)
