import { action, computed, observable } from 'mobx'

import analytics from '../analytics'
import RootStore from '../RootStore'

export default class AuthStore {
  @observable authToken: Pick<AuthToken, 'role' | 'auth'> | undefined | null
  tempAuthToken: Pick<AuthToken, 'role' | 'auth'> | undefined | null
  @observable loading = false
  adminMode = false
  codeSentAt = 0
  rootStore: RootStore
  @observable authFlow: 'create-account' | 'sign-in' | undefined
  sessionExpired = false
  postAuthenticationHash = ''

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  // Start the flow
  @action.bound async setAuthFlow(mode: 'create-account' | 'sign-in') {
    this.authFlow = mode
  }

  // End the flow
  @action.bound async finishAuthFlow(guestId?: string, userId?: string) {
    const { userStore, routeHandler } = this.rootStore

    this.sessionExpired = false
    this.authFlow = undefined
    this.tempAuthToken = undefined

    if (this.userRole === null) {
      await this.createGuestUser_api()
      this.syncInStorage()

      routeHandler.redirectToHomeAndFetchCategories()
      return
    }

    if (guestId && userId) {
      // Identify user in updateUserTraits_api BEFORE aliasing the guestId
      await userStore.updateUserTraits_api()
      analytics.alias(guestId, userId)
    }

    this.syncInStorage()
  }

  // Create a guest user
  createGuestUser_api = async () => {
    const { communication, userStore } = this.rootStore

    try {
      const response = await communication.requester.createGuestUser({})
      if (response.createGuestUser) {
        const { authToken, user } = response.createGuestUser
        this.authToken = authToken as Pick<AuthToken, 'role' | 'auth'>
        this.sessionExpired = false
        userStore.set(user)

        // Track
        await userStore.updateUserTraits_api()

        this.syncInStorage()
        userStore.syncInStorage()
      }
    } catch {
      return null
    }
  }

  // Find a user from a mobile number
  @action.bound async identifyWithMobile_api(mobile: string): Promise<boolean> {
    const { communication } = this.rootStore
    this.loading = true

    try {
      const response = await communication.requester.identifyWithMobile({ mobile })
      this.loading = false

      this.tempAuthToken = response.identifyWithMobile?.authToken as Pick<AuthToken, 'role' | 'auth'>

      return !!this.tempAuthToken
    } catch (e) {
      this.loading = false
      throw e
    }
  }

  @action.bound async createNewUserWithMobile_api(mobile: string): Promise<boolean> {
    const { communication, userStore } = this.rootStore
    this.loading = true

    try {
      const guestId = userStore.data._id

      const response = await communication.requester.createNewUserWithMobile({ mobile, guestId })
      this.loading = false

      this.tempAuthToken = response.createNewUserWithMobile?.authToken as Pick<AuthToken, 'role' | 'auth'>

      return !!this.tempAuthToken
    } catch {
      this.loading = false
      return false
    }
  }

  // Send the verification code to mobile number
  @action.bound async sendMobileVerification_api(mobile: string): Promise<boolean> {
    const { communication } = this.rootStore
    this.loading = true

    try {
      const response = await communication.requester.sendMobileVerification({ mobile, silent: this.adminMode })
      this.loading = false
      this.codeSentAt = Date.now()
      const authToken = response.sendMobileVerification?.authToken as Pick<AuthToken, 'role' | 'auth'>

      // Return false if no authToken
      if (!authToken) {
        return false
      }

      this.tempAuthToken = authToken

      return !!this.tempAuthToken
    } catch {
      this.loading = false
      return false
    }
  }

  // Verify the mobile number with code sent to user phone
  @action.bound async verifyMobileAndSignIn_api(mobile: string, code: string): Promise<boolean> {
    const { communication, userStore } = this.rootStore
    this.loading = true

    const guestId = `${userStore.data._id}`

    try {
      const response = await communication.requester.verifyMobileAndSignIn({ mobile, code })
      const data = response.verifyMobileAndSignIn
      if (data?.authToken && data.user) {
        const { authToken, user } = data
        this.authToken = authToken as Pick<AuthToken, 'role' | 'auth'>

        // Set user data
        userStore.set(user)

        // Finished auth flow
        this.finishAuthFlow(guestId, user._id)

        userStore.syncInStorage()
        analytics.track('User Logged Into Website')
      }

      this.loading = false

      return !!data?.authToken
    } catch {
      this.loading = false
      return false
    }
  }

  @action.bound async verifyMobileAndCompleteAccount_api(mobile: string, code: string, userData: RegistrationDetails): Promise<boolean> {
    const { communication, userStore } = this.rootStore
    this.loading = true

    const guestId = `${userStore.data._id}`

    try {
      const response = await communication.requester.verifyMobileAndCompleteAccount({ mobile, code, userData })

      const data = response.verifyMobileAndCompleteAccount
      if (data?.authToken && data.user) {
        const { authToken, user } = data
        this.authToken = authToken as Pick<AuthToken, 'role' | 'auth'>

        // Set user data
        userStore.set(user)

        // Finished auth flow
        this.finishAuthFlow(guestId, user._id)

        userStore.syncInStorage()
        analytics.completeRegistration()

        analytics.track('User Signed Up')
      }

      this.loading = false

      return !!data?.authToken
    } catch {
      this.loading = false
      return false
    }
  }

  // Log out the user from current session
  logout_api = async () => {
    const { communication, clearAllData } = this.rootStore

    // Logout on the api
    try {
      await communication.requester.logout({})

      // Create a new guest user
      this.authToken = null
      await this.createGuestUser_api()
      this.syncInStorage()
      clearAllData()
    } catch {
      return null
    }
  }

  setSessionExpired(expired: boolean) {
    this.sessionExpired = expired
  }

  // Manually expire the JWT token, for testing
  expireToken = () => {
    this.authToken.auth = 'LOCALLY_EXPIRED_TOKEN'
    this.syncInStorage()
  }

  // Sync data in local storage
  @action.bound syncInStorage = (): void => {
    this.rootStore.storeDataSync.saveStoreToStorage('AUTH')
  }

  // Add value to a variable
  @action.bound inflate(data: any) {
    Object.keys(data).forEach(key => {
      this[key] = data[key]
    })
  }

  clearData() {
    this.authToken = null
    this.syncInStorage()
  }

  // Use when we want to save to storage
  serialize() {
    return {
      authToken: this.authToken,
    }
  }

  // Get user role
  @computed get userRole() {
    return this.authToken ? this.authToken.role : null
  }

  // If we're in the auth flow we want to use the tempAuthToken
  getAccessAuthToken(operationName?: string) {
    const useTempTokenOperations = ['sendMobileVerification', 'verifyMobileAndSignIn', 'verifyMobileAndCompleteAccount']
    return useTempTokenOperations.includes(operationName) ? this.tempAuthToken : this.authToken
  }

  // Check if auth token exists with correct role
  @computed get isLoggedIn(): boolean {
    const allowedRoles = ['CLIENT', 'PROFESSIONAL']
    return !!this.authToken && !!this.authToken.auth && allowedRoles.includes(this.authToken.role!)
  }
}
