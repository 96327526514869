import dynamic from 'next/dynamic'

export { default as NavMenu } from './NavMenu/NavMenu'
export { default as CookieBanner } from './CookieBanner'
export { default as PostcodePopup } from './PostcodePopup'
export { default as CartSummary } from './CartSummary/CartSummary'
export { default as CartButton } from './CartSummary/CartButton'
export { default as MobileToolbar } from './MobileToolbar'
export { default as RectButton } from './RectButton'
export { default as CircleButton } from './CircleButton'
export { default as TextButton } from './TextButton'
export { default as Logo } from './Logo'
export { default as LogoIcon } from './LogoIcon'
export { default as ListButton } from './ListButton'
export { default as LoadingOverlay } from './LoadingOverlay'
export { default as Clickable } from './Clickable'
export { default as SplashScreen } from './SplashScreen'
export { default as GenericHorizontalScroller } from './GenericHorizontalScroller'

export const ResponsiveToolbar = dynamic(() => import('./ResponsiveToolbar'))
export const Footer = dynamic(() => import('./Footer'))
export const FormTextField = dynamic(() => import('./FormTextField'))
export const FormSelect = dynamic(() => import('./FormSelect'))
export const GenericPopup = dynamic(() => import('./GenericPopup'))
export const Review = dynamic(() => import('./Review'))
export const FormPriceField = dynamic(() => import('./FormPriceField'))
export const Avatar = dynamic(() => import('./Avatar'))
export const ResponsivePageModal = dynamic(() => import('./ResponsivePageModal'))
export const Carousel = dynamic(() => import('./Carousel'))
export const CardLogo = dynamic(() => import('./CardLogo'))
export const AssuranceText = dynamic(() => import('./AssuranceText'))
export const TickBox = dynamic(() => import('./TickBox'))
export const SelectCategories = dynamic(() => import('./SelectCategories'))
export const StyledMarkdown = dynamic(() => import('./StyledMarkdown'))
export const NewAccountBadge = dynamic(() => import('./NewAccountBadge'))
export const TrustPilotBox = dynamic(() => import('./TrustPilotBox'))
export const GooglePlacesAutocomplete = dynamic(() => import('./GooglePlacesAutocomplete'))
export const BookingTag = dynamic(() => import('./BookingTag'))
export const TreatmentCategoryScroller = dynamic(() => import('./TreatmentCategoryScroller'))
export const RatingPopup = dynamic(() => import('./RatingPopup'))
export const AddTipPopup = dynamic(() => import('./AddTipPopup'))
export const ProfessionalCarousel = dynamic(() => import('./ProfessionalCarousel'))
export const InstructionsView = dynamic(() => import('./InstructionsView'))
export const ImageDialog = dynamic(() => import('./ImageDialog'))
export const BookingRow = dynamic(() => import('./BookingRow'))
export const RatingSummary = dynamic(() => import('./RatingSummary'))
export const NewToPlatform = dynamic(() => import('./NewToPlatform'))
export const ProfessionalStats = dynamic(() => import('./Profile/ProfessionalStats'))
export const DBSBanner = dynamic(() => import('./Profile/DBSBanner'))
