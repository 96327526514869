import analytics from '../../src/stores/analytics'
import RootStore from '../../src/stores/RootStore'
import { professionalCanDoTreatments, professionalCannotDoOptions, professionalCoversPostcode, professionalUncoveredOptionNames, professionalUncoveredTreatmentNames, showGenderPreferencePopup } from '../helpers/professionalBookingHelper'
import { abbreviatedUserName } from '../lib/utils'
import BookingModel, { $BookingItemTreatment } from '../stores/BookingStore/BookingModel'
import { $BookingFlow } from '../stores/CheckoutStore/CheckoutStore'
import { $Professional } from '../stores/ProfessionalStore/ProfessionalModel'
import { $TreatmentModel } from '../stores/TreatmentStore/TreatmentModel'
import { $Postcode } from './ServiceAreaHandler'
import { currencyFormat } from '../../shared-lib/currency'
import { upperCase } from 'voca'

export default class NewBookingHandler {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  confirmProfessionalCoversPostcode = (postcode: $Postcode, professional: $Professional | undefined, callbackFn: (removed: boolean) => void) => {
    const { uiStore, i18n: { s } } = this.rootStore

    // Check pro covers postcode
    if (professionalCoversPostcode(postcode._id, professional)) {
      callbackFn(false)
    } else {
      uiStore.showMessage({
        title: s('POSTCODE_OUTSIDE_PRO_AREA_TITLE'),
        body: s('POSTCODE_OUTSIDE_PRO_AREA_BODY', abbreviatedUserName(professional), upperCase(postcode.code)),
        buttons: [
          {
            title: s('Remove therapist'),
            onPress: () => {
              callbackFn(true)
            },
          },
          {
            title: s('Cancel'),
          },
        ],
      })
    }
  }

  confirmProfessionalCanDoTreatment = (treatment: $TreatmentModel, professional: $Professional, callbackFn: (action: string) => void) => {
    const { uiStore, i18n: { s } } = this.rootStore

    const canDoTreatment = professionalCanDoTreatments([treatment.data._id], professional)

    if (!canDoTreatment) {
      uiStore.showMessage({
        title: s('Cannot add treatment'),
        body: s('PRO_CANNOT_DO_TREATMENT', abbreviatedUserName(professional), treatment.data.name),
        buttons: [
          {
            title: s('Remove $0', professional.firstName),
            onPress: () => {
              callbackFn('removePro')
            },
          },
          {
            title: s('Cancel'),
            onPress: () => {
              callbackFn('cancel')
            },
          },
        ],
      })
    } else {
      callbackFn('continue')
    }
  }

  tryBookWithProfessional = (newProfessional: $Professional, callback: () => void) => {
    const {
      checkoutStore: {
        booking: {
          treatmentIds,
          data: { postcode, items, clientDetails },
          optionIds,
        },
        provisionalProfessional,
        emptyBasket,
        removeProvisionalProfessionalFromBooking,
      },
      uiStore,
      i18n: { s },
    } = this.rootStore

    if (!newProfessional) return

    const { firstName, professionalProfile } = newProfessional
    const { genderPreference } = professionalProfile || {}

    // Check pro covers postcode
    if (!professionalCoversPostcode(postcode._id, newProfessional)) {
      uiStore.showMessage({
        title: s('POSTCODE_OUTSIDE_PRO_AREA_TITLE'),
        body: s('POSTCODE_OUTSIDE_PRO_AREA_BODY', firstName, upperCase(postcode.code)),
        buttons: [
          {
            title: s('Close'),
          },
        ],
      })

      return
    }

    //Check if pro covers gender
    if (genderPreference && genderPreference !== 'none' && clientDetails[0].gender !== genderPreference) {
      showGenderPreferencePopup(
        this.rootStore,
        firstName,
        genderPreference,
        () => this.tryBookWithProfessional(newProfessional, callback))
      return
    }

    // Pro doesn't cover all treatments
    const doesNotCoverTreatments = treatmentIds.length > 0 && !professionalCanDoTreatments(treatmentIds, newProfessional)
    const doesNotCoverOptions = optionIds.length > 0 && professionalCannotDoOptions(optionIds, newProfessional)

    if (doesNotCoverTreatments || doesNotCoverOptions) {
      let uncoveredTreatments = ''

      if (doesNotCoverTreatments) {
        uncoveredTreatments = professionalUncoveredTreatmentNames(treatmentIds, newProfessional, items)
      }
      if (doesNotCoverOptions) {
        const uncoveredOptions = professionalUncoveredOptionNames(optionIds, newProfessional, items)
        uncoveredTreatments = doesNotCoverTreatments ? `${uncoveredTreatments}, ${uncoveredOptions}` : uncoveredOptions
      }

      uiStore.showMessage({
        title: s('TREATMENT_NOT_COVERED_BY_PRO_TITLE'),
        body: s('TREATMENT_NOT_COVERED_BY_PRO_BODY', abbreviatedUserName(newProfessional), uncoveredTreatments),
        buttons: [
          {
            title: s('Remove treatments and continue'),
            onPress: () => {
              emptyBasket()
              callback()
            },
          },
          {
            title: s('Cancel'),
          },
        ],
      })

      return
    }

    // Booking already has a pro
    if (provisionalProfessional && provisionalProfessional._id !== newProfessional._id) {
      uiStore.showMessage({
        title: s('CHANGING_PRO_TITLE'),
        body: s('CHANGING_PRO_BODY', provisionalProfessional?.firstName),
        buttons: [
          {
            title: s('Continue with $0', firstName),
            onPress: () => {
              removeProvisionalProfessionalFromBooking()
              callback()
            },
          },
          {
            title: s('Cancel'),
          },
        ],
      })

      return
    }

    callback()
  }

  preparePreCheckout = async (): Promise<$BookingFlow | 'error'> => {
    const { professionalMatcherStore, checkoutStore, userStore, editBookingHandler } = this.rootStore

    if (checkoutStore.bookingIsEmpty) {
      return 'error'
    }

    // Ensure editing booking is removed
    editBookingHandler.clearEditingBookingId()

    // Update user properties
    await userStore.updateUserTraits_api()

    const { provisionalProfessional } = checkoutStore

    let flow: $BookingFlow = 'treatment:time:pro'
    const sessionContext: any = {}

    // If we have a pro we go straight to calender
    if (provisionalProfessional) {
      flow = 'pro:treatment:time'
    }

    professionalMatcherStore.reset()
    analytics.resetSessionContext()
    analytics.setSessionContext(sessionContext)
    checkoutStore.setFlow(flow)
    checkoutStore.resetBooking()

    analytics.ecommerceEvent('Checkout Started', undefined, checkoutStore.booking)

    return flow
  }

  rebook = async (booking: BookingModel, source: string, navigationCallback: (id: string) => void) => {
    const {
      checkoutStore: { bookWithProfessional, addItem, emptyBasket },
      professionalStore,
      treatmentStore,
      uiStore,
    } = this.rootStore

    let { data: { professional } } = booking
    const { data: { totalPrice } } = booking

    if (professional) {
      if (!professional.professionalProfile) {
        // Get missing professionalProfile for tryBookWithProfessional to check postcodes still match
        const fullProfessional = await professionalStore.fetchItem_api(professional._id)
        professional = fullProfessional!.data
      }

      const treatments = await treatmentStore.rebookTreatments_api(booking.data._id)

      this.tryBookWithProfessional(professional, () => {
        emptyBasket()
        bookWithProfessional(professional!._id, source)

        for (const treatment of treatments) {
          if (treatment && treatment._id) {
            addItem(treatment as $BookingItemTreatment)
          }
        }

        const { checkoutStore: { booking: { totalPriceComputed } } } = this.rootStore

        if (totalPrice !== totalPriceComputed) {
          uiStore.showMessage({
            title: 'Some treatments have changed',
            body: `Due to a change, your booking price has changed from ${currencyFormat(totalPrice)} to ${currencyFormat(totalPriceComputed)}`,
          })
        }

        navigationCallback(professional!._id)
      })
    }
  }

  bookTradeTest = async (tradeTestProfessional: $Professional, source: string, navigationCallback: () => void) => {
    const { treatmentStore, uiStore, checkoutStore, i18n: { s } } = this.rootStore
    const treatmentIds = tradeTestProfessional.professionalProfile?.treatments
      .filter(treatment => treatment.type === 'trade-test')
      .map(treatment => treatment._id || '')
      .filter(item => item !== '')

    if (!treatmentIds || treatmentIds.length === 0) {
      uiStore.showMessage({
        title: s('Error'),
        body: s('PRO_NO_TRADE_TEST_TREATMENT'),
      })
    } else {
      await treatmentStore.fetchItems_api({
        variables: {
          filter: {
            _id: { $in: treatmentIds },
            regions: { $in: checkoutStore.bookingRegionOrDefault },
          },
        },
      })

      const treatments = treatmentStore.findItemByIds(treatmentIds)

      if (treatments.length > 0) {
        checkoutStore.emptyBasket()
        this.tryBookWithProfessional(tradeTestProfessional, async () => {
          checkoutStore.bookWithProfessional(tradeTestProfessional._id, source)
          checkoutStore.booking.data.tags = [...checkoutStore.booking.data.tags, 'tradeTest']
          treatments.forEach(t => checkoutStore.addItem(t.data))
          navigationCallback()
        })
      } else {
        uiStore.showMessage({
          title: s('Error'),
          body: s('PRO_NO_TRADE_TEST_TREATMENT_AREA', checkoutStore.bookingRegionOrDefault),
        })
      }
    }
  }
}
