import React from 'react'

import { Box } from '@material-ui/core'
import { InfoSharp } from '@material-ui/icons'

import Clickable from './Clickable'

interface Props {
  onClick: () => void
}

const InfoIconButton = ({ onClick }: Props) => {
  return <Clickable onClick={onClick}>
    <Box p={1} marginTop='-5px' marginBottom='-6px'>
      <InfoSharp style={{ fontSize: 18 }} />
    </Box>
  </Clickable>
}

export default InfoIconButton