import { Box, WithStyles, withStyles } from '@material-ui/core'
import React from 'react'
import { colors } from '../theme'
import { styleCreate } from '../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  onClick: () => void
  type?: 'primary' | 'secondary' | 'outline'
  icon?: any
  size?: string
  disabled?: boolean
}

const styles = styleCreate(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 1,
  },
  primaryContainer: {
    backgroundColor: colors.BLACK900,
  },
  secondaryContainer: {
    backgroundColor: colors.BG100,
  },
  outlineContainer: {
    borderColor: colors.PRI200,
    borderWidth: 2,
  },
  largeContainer: {
    height: theme.spacing(9),
    width: theme.spacing(9),
    borderRadius: theme.spacing(4.5),
  },
  mediumContainer: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    borderRadius: theme.spacing(3.5),
  },
  smallContainer: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    borderRadius: theme.spacing(3),
  },
  tinyContainer: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: theme.spacing(2),
  },
  disabled: {
    opacity: 0.4,
  },
}))

const CircleButton = (props: Props) => {
  const { onClick, size = 'medium', type = 'primary', disabled = false, icon, classes } = props

  const containerStyle = `${classes[`${size}Container`]} ${classes[`${type}Container`]} ${classes.container} ${!!disabled && classes.disabled}`

  return <>
    <Box
      onClick={onClick}
      display='flex'
      alignItems='center'
      justifyContent='center'
      className={containerStyle}
    >
      {icon}
    </Box>
  </>
}

export default withStyles(styles)(CircleButton)