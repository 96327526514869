import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Box, Typography, WithStyles, withStyles } from '@material-ui/core'
import { Avatar } from '../'
import RootStore from '../../src/stores/RootStore'
import { colors } from '../../theme'
import { styleCreate } from '../../lib/styleCreate'
import { LocalMallSharp } from '@material-ui/icons'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = styleCreate(theme => ({
  cartButton: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    height: theme.spacing(10),
    width: theme.spacing(10),
    borderRadius: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: colors.BLACK900,
    zIndex: 400,
    '&:hover': {
      backgroundColor: colors.BLACK910,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      top: theme.spacing(4),
      right: theme.spacing(4),
    },
    cursor: 'pointer',
  },
  icon: {
    width: theme.spacing(4),
  },
  itemCount: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: theme.spacing(2),
    position: 'absolute',
    right: theme.spacing(-1),
    top: theme.spacing(-0.5),
    background: colors.STONE400,
    textAlign: 'center',
  },
  itemCountText: {
    color: 'black',
    letterSpacing: 0,
  },
  postcode: {
    marginRight: theme.spacing(3),
    color: 'white',
  },
}))

@inject('store')
@observer
class CartButton extends Component<Props> {
  render() {
    const { store, classes } = this.props
    const { checkoutStore, uiStore } = store!
    const { booking, provisionalProfessional } = checkoutStore
    const { items } = booking.data
    const { toggleCartVisible } = uiStore

    return (
      <Box onClick={() => { toggleCartVisible(true) }} className={classes.cartButton}>
        <Box className={classes.icon}>
          <LocalMallSharp color='secondary' />
        </Box>

        {provisionalProfessional && <Box marginRight='4px' marginLeft={-1}>
          <Avatar
            user={provisionalProfessional}
            size={52}
          />
        </Box>}

        <Box className={classes.itemCount}>
          <Typography className={classes.itemCountText} variant='body2'>{items.length}</Typography>
        </Box>
      </Box>
    )
  }
}

export default withStyles(styles)(CartButton)
