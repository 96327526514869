import { Provider } from 'mobx-react'
import App from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import React from 'react'
import { ThemeProvider } from '@material-ui/core'
import analytics from '../src/stores/analytics'
import RootStore from '../src/stores/RootStore'
import { theme, colors } from '../theme'
import MainView from './MainView'
import { I18nContext } from '../shared-lib/i18n/i18n'
import { zoomUnlocked } from '../lib/lockScreenZoom'
import { getBrowserTimezone } from '../shared-consumer/lib/utils'
import packageJSON from '../package.json'

class WebBooking extends App {
  store = new RootStore(Router)
  loaded = false

  componentDidMount = async () => {
    this.loaded = false

    // If preloading then do not load analytics and store etc..
    if (window.__preload) return

    // Setup analytics
    analytics.init()

    const startDate = Date.now()

    analytics.track('Web Load Start')

    this.store.storeDataSync.setStorage(localStorage)
    await this.loadStore()

    // Bind to Router events
    Router.events.on('routeChangeStart', _url => {
      NProgress.start()
    })
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())

    // Handle the initial route
    await this.store.setupRouting()

    const loadTimeInSeconds = (Date.now() - startDate) / 1000
    analytics.track('Web Load Finish', { loadTime: loadTimeInSeconds })

    this.loaded = true
  }

  loadStore = async () => {
    const communicationLoaded = await this.store.loadCommunication({
      apiDefault: process.env.API_DEFAULT,
      platform: process.env.PLATFORM,
      timezone: getBrowserTimezone(),
      env: process.env.SS_ENV,
      addNetworkLatency: parseInt(process.env.ADD_NETWORK_LATENCY),
      appVersion: packageJSON.version,
      override: process.env.API_OVERRIDE_URL,
    })

    const { s } = this.store.i18n

    if (!communicationLoaded) {
      this.store.uiStore.showMessage({
        title: s('NO_INTERNET_TITLE'),
        body: s('NO_INTERNET_BODY'),
        buttons: [
          {
            title: 'Try again',
            onPress: () => {
              // Delay to ensure popup is hidden before re-triggering
              setTimeout(() => {
                this.loadStore()
              }, 1000)
            },
          },
        ],
      })

      return
    }

    await this.store.loadStore()
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <>
        <Head>
          <meta charSet='utf-8' />
          <meta
            name='viewport'
            content={zoomUnlocked}
          />
        </Head>
        <div aria-label='app_screen'>
          <Provider store={this.store}>
            <I18nContext.Provider value={this.store.i18n}>
              <ThemeProvider theme={theme}>
                <MainView>
                  <Component {...pageProps} />
                </MainView>
              </ThemeProvider>
            </I18nContext.Provider>
          </Provider>

          {globalStyles}
        </div>
      </>
    )
  }
}

const globalStyles = <style>{`
  .hideScrollbars {
    -ms-overflow-style: none;
  }
  .hideScrollbars::-webkit-scrollbar {
    display: none;
  }
  .google-places-input {
    width: 100%;
    padding: 16px 15px 15px;
    border-width: 0px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5;
    font-family: Muli, sans-serif;
    color: ${colors.INK900};
    box-shadow: ${colors.CARD_SHADOW} 0px 0px 15px;
  }
  .google-places-input:focus {
    outline: none;
  }
  .pac-container {
    z-index: 1300;
    border-radius: 0 0 10px 10px;
    box-shadow: #653ED310 0px 3px 8px;
    border: none;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-top: -10px;
  }
  .pac-item {
    align-items: center;
    border-top: 0;
    padding: 4px 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: ${colors.INK900};
    font-family: Muli, sans-serif;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5;

    border-bottom: 1px solid ${colors.BG400};
    white-space: pre-line;
  }
  .pac-item:hover {
    background-color: ${colors.BLACK900 + '20'};
  }
  .pac-logo:after {
    margin-right: 10px;
    margin-top: 5px;
  }
  .pac-icon {
    display: none;
  }
  .pac-item-query {
    color: ${colors.INK900};
    font-family: Muli, sans-serif;
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.5;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`}</style>

export default WebBooking
