import { action, observable } from 'mobx'
import { SingletonRouter } from 'next/router'

import ErrorHandler from '../../lib/ErrorHandler'
import NewBookingHandler from '../../shared-consumer/handlers/NewBookingHandler'
import ServiceAreaHandler from '../../shared-consumer/handlers/ServiceAreaHandler'
import StoreDataSync, { $StoreDataSync } from '../../shared-lib/lib/StoreDataSync'
import BookingStore, { $BookingStore } from '../../shared-consumer/stores/BookingStore/BookingStore'
import CardStore from '../../shared-consumer/stores/CardStore/CardStore'
import CategoryStore, { $CategoryStore } from '../../shared-consumer/stores/CategoryStore/CategoryStore'
import CheckoutStore from '../../shared-consumer/stores/CheckoutStore/CheckoutStore'
import FavouriteStore from '../../shared-consumer/stores/FavouriteStore/FavouriteStore'
import MarkdownStore from '../../shared-consumer/stores/MarkdownStore/MarkdownStore'
import ProfessionalMatcherStore from '../../shared-consumer/stores/ProfessionalMatcherStore/ProfessionalMatcherStore'
import ProfessionalStore from '../../shared-consumer/stores/ProfessionalStore/ProfessionalStore'
import ReviewStore from '../../shared-consumer/stores/ReviewStore/ReviewStore'
import TransactionStore from '../../shared-consumer/stores/TransactionStore/TransactionStore'
import TreatmentStore, { $TreatmentStore } from '../../shared-consumer/stores/TreatmentStore/TreatmentStore'
import UserAddressStore, { $UserAddressStore } from '../../shared-consumer/stores/UserAddressStore/UserAddressStore'
import UserStore from '../../shared-consumer/stores/UserStore/UserStore'
import Communication from '../../shared-lib/communication/Communication'
import RouteHandler from '../handlers/RouteHandler'
import analytics from './analytics'
import AuthStore from './AuthStore/AuthStore'
import i18n, { $i18n } from '../../shared-lib/i18n/i18n'
import { ModalController } from './ModalController/ModalController'
import UIStore from './UIStore/UIStore'
import SubscriptionHandler from '../handlers/SubscriptionHandler'
import ZendeskHandler from '../handlers/ZendeskHandler'
import ParkingInformationStore, { $ParkingInformationStore } from '../../shared-consumer/stores/ParkingInformationStore/ParkingInformationStore'
import PromoCodeStore, { $PromoCodeStore } from '../../shared-consumer/stores/PromoCodeStore/PromoCodeStore'
import TestUIStore from './TestUIStore/TestUIStore'
import StripeIntentHandler from '../handlers/StripeIntentHandler'
import EditBookingHandler from '../../shared-consumer/handlers/EditBookingHandler'
import DropdownMessageHandler from '../handlers/DropDownMessageHandler'
import { ReviewBookingHandler } from '../../shared-consumer/handlers/ReviewBookingHandler'
import getApiUrl from '../../shared-lib/communication/getApiUrl'
import OrderTrackerHandler from '../../lib/OrderTrackerHandler'
import languages from '../../shared-consumer/languages'
import ChatStore, { $ChatStore } from '../../shared-consumer/stores/ChatsStore/ChatStore'

declare global {
  interface Window {
    amplitude: any
    branch: any
    Trustpilot: any
    localStorage: any
    __initialHash: string
    __preload: boolean
    zE: any
    hj: any
  }
}

export type $RootStore = RootStore

export default class RootStore {
  @observable readyStatus = 0
  @observable remoteConfig: any = {}
  @observable appVer: any = {}
  communication: Communication
  authStore: AuthStore
  userStore: UserStore
  cardStore: CardStore
  bookingStore: $BookingStore
  chatStore: $ChatStore
  professionalStore: ProfessionalStore
  transactionStore: TransactionStore
  markdownStore: MarkdownStore
  categoryStore: $CategoryStore
  userAddressStore: $UserAddressStore
  treatmentStore: $TreatmentStore
  reviewStore: ReviewStore
  uiStore: UIStore
  testUiStore: TestUIStore
  i18n: $i18n
  modalController: ModalController
  checkoutStore: CheckoutStore
  favouriteStore: FavouriteStore
  serviceAreaHandler: ServiceAreaHandler
  professionalMatcherStore: ProfessionalMatcherStore
  parkingInformationStore: $ParkingInformationStore
  promoCodeStore: $PromoCodeStore
  errorHandler: ErrorHandler
  orderTrackerHandler: OrderTrackerHandler
  routeHandler: RouteHandler
  storeDataSync: $StoreDataSync
  newBookingHandler: NewBookingHandler
  router: SingletonRouter
  subscriptionHandler: SubscriptionHandler
  zendeskHandler: ZendeskHandler
  stripeIntentHandler: StripeIntentHandler
  editBookingHandler: EditBookingHandler
  dropdownMessageHandler: DropdownMessageHandler
  reviewBookingHandler: ReviewBookingHandler
  config: any = {}

  constructor(router: SingletonRouter) {
    this.config.name = process.env.SS_ENV
    this.config.platform = process.env.PLATFORM

    this.authStore = new AuthStore(this)
    this.userStore = new UserStore({}, this)
    this.bookingStore = new BookingStore(this)
    this.chatStore = new ChatStore(this)
    this.uiStore = new UIStore(this)
    this.testUiStore = new TestUIStore(this)
    this.professionalStore = new ProfessionalStore(this)
    this.transactionStore = new TransactionStore(this)
    this.treatmentStore = new TreatmentStore(this)
    this.userAddressStore = new UserAddressStore(this)
    this.cardStore = new CardStore(this)
    this.markdownStore = new MarkdownStore(this)
    this.modalController = new ModalController()
    this.orderTrackerHandler = new OrderTrackerHandler(this)

    this.checkoutStore = new CheckoutStore(this)
    this.categoryStore = new CategoryStore(this)
    this.favouriteStore = new FavouriteStore(this)
    this.reviewStore = new ReviewStore(this)
    this.parkingInformationStore = new ParkingInformationStore(this)
    this.promoCodeStore = new PromoCodeStore(this)

    this.serviceAreaHandler = new ServiceAreaHandler(this)
    this.professionalMatcherStore = new ProfessionalMatcherStore(this)
    this.routeHandler = new RouteHandler(this)
    this.newBookingHandler = new NewBookingHandler(this)
    this.stripeIntentHandler = new StripeIntentHandler(this)
    this.editBookingHandler = new EditBookingHandler(this)
    this.dropdownMessageHandler = new DropdownMessageHandler()
    this.reviewBookingHandler = new ReviewBookingHandler(this)

    this.router = router

    this.subscriptionHandler = new SubscriptionHandler(this)

    // i18n
    this.i18n = new i18n(languages, 'en')

    // Setup storage
    this.storeDataSync = new StoreDataSync(process.env.STORAGE_KEY)
    this.storeDataSync.addSyncStore('USER_ADDRESSES', this.userAddressStore)
    this.storeDataSync.addSyncStore('UI', this.uiStore)
    this.storeDataSync.addSyncStore('USER', this.userStore)
    this.storeDataSync.addSyncStore('AUTH', this.authStore)
    this.storeDataSync.addSyncStore('CHECKOUT', this.checkoutStore)
    this.storeDataSync.addSyncStore('ORDERS', this.orderTrackerHandler)
    this.storeDataSync.addSyncStore('CHAT', this.chatStore)

    if (process.env.SS_ENV !== 'production') {
      this.storeDataSync.addSyncStore('UITEST', this.testUiStore)
    }

    this.errorHandler = new ErrorHandler(this)

    this.zendeskHandler = new ZendeskHandler(this)
  }

  @action.bound loadCommunication = async (config: any) => {
    // Get API url and load config
    try {
      const { wsHost, apiHost } = await getApiUrl({
        apiDefault: config.apiDefault,
        platform: config.platform,
        appVersion: config.appVersion,
        useSecure: this.config.name === 'development' ? '' : 's',
        override: config.override,
      })

      config.wsHost = wsHost
      config.apiHost = apiHost
    } catch (e) {
      return false
    }

    this.communication = new Communication(this, {
      apiHost: config.apiHost,
      wsHost: config.wsHost,
      platform: config.platform,
      timezone: config.timezone,
      env: config.env,
      addNetworkLatency: config.addNetworkLatency,
    })

    return true
  }

  @action.bound loadStore = async () => {
    this.readyStatus = 0

    this.zendeskHandler.listenToEvents()

    const campaignTracked = analytics.populateCampaignData()

    // Get settings from DB
    const isApiUp = await this.checkApiIsUp()

    if (!isApiUp) {
      this.uiStore.showMessage({
        title: this.i18n.s('NO_INTERNET_TITLE'),
        body: this.i18n.s('NO_INTERNET_BODY'),
        buttons: [
          {
            title: 'Try again',
            onPress: () => {
              // Delay to ensure popup is hidden before re-triggering
              setTimeout(() => {
                this.loadStore()
              }, 1000)
            },
          },
        ],
      })

      return
    }

    // Get data from storage
    this.transferOldStorage()
    await this.getDataFromlocalStorage()

    // If no auth, create guest user
    const { authFlow, authToken, createGuestUser_api } = this.authStore
    if (!authToken) await createGuestUser_api()

    // Fetch categories for side menu
    await this.fetchCategoryData_api()

    this.readyStatus = 1

    // Get config
    await this.getConfig_api()

    // Setup AB Tests
    const abTestOverrides = {}
    Object.keys(this.router.query)
      .filter(key => key.slice(0, 7) === 'ABTEST_')
      .forEach(key => {
        const string = this.router.query[key] as string
        abTestOverrides[key.replace('ABTEST_', '')] = JSON.parse(string)
      })

    // The testing environment has AB tests disabled
    if (!process.env.DISABLE_AB_TESTS) {
      analytics.startABTests(this.remoteConfig?.abTests || {}, abTestOverrides)
    }

    this.readyStatus = 2

    // TODO is this required?
    if (authFlow) {
      // Do not call category fetch
      return
    }

    // Get utm data
    if (campaignTracked) await this.userStore.trackCampaign_api(analytics.campaignData)

    if (this.authStore.isLoggedIn) {
      await this.fetchUserData()
      this.checkoutStore.updateSessionContext()

      // remove all fees and discounts after store is hydrated
      if (!window.location.href.includes('/booking/summary')) {
        this.checkoutStore.clearFeesAndDiscounts()
      }

      this.errorHandler.setUserContext(this.userStore.data)

      // Show review booking modal
      this.showReviewBookingModal()
    }

    // Always identify the user
    await this.userStore.updateUserTraits_api()
  }

  // Method fetch category data for region
  fetchCategoryData_api = async () => {
    const { categoryStore, checkoutStore } = this

    const region = checkoutStore.bookingRegionOrDefault

    await categoryStore.fetchItems_api({
      variables: {
        region,
      },
      clear: true,
    })
  }

  // Fetch stores from sync storage
  @action.bound async getDataFromlocalStorage(): Promise<void> {
    // TODO: Could add a syncAll on localStorage
    await this.storeDataSync.inflateStoreFromStorage('UI')
    await this.storeDataSync.inflateStoreFromStorage('USER')
    await this.storeDataSync.inflateStoreFromStorage('CHECKOUT')
    await this.storeDataSync.inflateStoreFromStorage('USER_ADDRESSES')
    await this.storeDataSync.inflateStoreFromStorage('AUTH')
    await this.storeDataSync.inflateStoreFromStorage('ORDERS')

    if (process.env.SS_ENV !== 'production') {
      await this.storeDataSync.inflateStoreFromStorage('UITEST')
    }
  }

  setupRouting = async () => {
    this.routeHandler.handleInitialRoute()
    this.subscriptionHandler.listenToRouteChange()

    // If there's no postcode we should show postcode dialog
    if (!this.checkoutStore.hasPostcode && this.routeHandler.showPopupOnRoute()) {
      this.uiStore.showPostCodeDialog()
    }
  }

  showReviewBookingModal = () => {
    const { reviewBookingHandler, uiStore } = this

    // Get reviewable booking
    const reviewableBooking = reviewBookingHandler.latestReviewableBooking()

    if (reviewableBooking) {
      // We need a slightly longer timeout to overcome the initial category redirect
      setTimeout(() => {
        uiStore.showReviewPopup(reviewableBooking.data._id)
      }, 500)
    }
  }

  // Setup analytics and load store data
  @action.bound async fetchUserData(): Promise<void> {
    await this.userAddressStore.fetchItems_api()
    await this.favouriteStore.fetchItems_api()
    await this.bookingStore.fetchItems_api()

    // See if have booking to display
    await this.bookingStore.fetchUpcomingBooking_api()

    // Also fetch reviewable booking
    await this.reviewBookingHandler.checkReviewableBooking()

    await this.chatStore.checkForNewUnreads()
    await this.chatStore.fetchItems_api()
  }

  checkApiIsUp = async (): Promise<boolean> => {
    try {
      const res = await this.communication.requester.isApiUp({})
      return res.isApiUp || false
    } catch (e) {
      return false
    }
  }

  getConfig_api = async (): Promise<boolean> => {
    const { s } = this.i18n

    try {
      const res = await this.communication.requester.getConfig()

      if (this.authStore.sessionExpired) return

      const remoteConfig = res.settings
      const appVer = res.appVer
      const blocked = res.checkBlockedUser

      if (!remoteConfig || !appVer || blocked === undefined) {
        this.uiStore.showMessage({
          title: s('Something went wrong'),
          body: s('Something went wrong contacting our server. Please try again.'),
          buttons: [
            { title: s('Retry'), onPress: this.getConfig_api },
          ],
        })
        return false
      }

      this.remoteConfig = remoteConfig.settings
      this.appVer = appVer

      if (blocked) {
        this.authStore.logout_api()

        this.uiStore.showMessage({
          title: s('Something went wrong'),
          body: s('This account has been blocked. Please contact the support for further information.'),
          buttons: [
            { title: s('Contact us'), onPress: this.uiStore.contactSupport },
            { title: s('Ok') },
          ],
        })
      }

      return true
    } catch (e) {
      return false
    }
  }

  // Clear all data from all stores
  clearAllData = () => {
    const {
      userStore,
      userAddressStore,
      cardStore,
      bookingStore,
      checkoutStore,
      favouriteStore,
    } = this

    bookingStore.clearData()
    checkoutStore.clearData()
    userStore.clearData()
    userAddressStore.clearData()
    cardStore.clearData()
    favouriteStore.clearData()
    analytics.reset()
  }

  // Transfer any old store data to new format
  transferOldStorage = () => {
    const transferToSyncStore = (oldKey: string, newKey: string) => {
      const data = localStorage.getItem(oldKey)
      if (data) {
        const key = this.storeDataSync.storageKeyFromKey(newKey)
        localStorage.setItem(key, data)
        localStorage.removeItem(oldKey)
      }
    }

    try {
      transferToSyncStore('authStore', 'AUTH')
      transferToSyncStore('userStore', 'USER')
    } catch (e) {
      // Error, user will be logged out!
      this.clearAllData()
      this.routeHandler.goToRootRoute()
    }
  }
}
