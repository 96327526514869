import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Divider, Typography, WithStyles, withStyles } from '@material-ui/core'
const SwipeableDrawer = dynamic(() => import('@material-ui/core').then(mod => mod.SwipeableDrawer))

import { $CategoryModel } from '../../shared-consumer/stores/CategoryStore/CategoryModel'

import RootStore from '../../src/stores/RootStore'
import { colors } from '../../theme/'
import categoryThemes from '../../theme/categoryThemes'
import TextButton from '../TextButton'
import AccountActions from './AccountActions'
import NavMenuLink from './NavMenuLink'
import { filteredProCategories } from '../../shared-consumer/helpers/professionalBookingHelper'
import dynamic from 'next/dynamic'
import GiftCardIcon from '../../public/img/navMenuIcons/giftCard.png'
import ContactIcon from '../../public/img/navMenuIcons/contact.png'
import { styleCreate } from '../../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
  drawer?: boolean
  visible?: boolean
}

const styles = styleCreate(theme => ({
  sideMenu: {
    height: '100vh',
    backgroundColor: colors.BG100,
    boxSizing: 'border-box' as const,
    overflow: 'auto',
  },
  drawer: {
    width: 400,
    maxWidth: '80vw',
    boxSizing: 'border-box' as const,
  },
  categories: {
    padding: theme.spacing(1, 4),
  },
  header: {
    padding: theme.spacing(2, 4),
  },
}))

@inject('store')
@observer
class NavMenu extends Component<Props> {
  render() {
    const { drawer, store, classes } = this.props
    const { i18n: { s }, uiStore, checkoutStore, categoryStore } = store!
    const { navVisible, toggleNavVisible, showRemoveProfessionalPopup } = uiStore
    const { provisionalProfessional, isTradeTestBooking } = checkoutStore
    const { activeCategories } = categoryStore

    const categories = provisionalProfessional
      ? filteredProCategories(activeCategories, provisionalProfessional)
      : activeCategories

    const content = <Box aria-label='nav_menu'>
      <Box className={classes.header}>
        <AccountActions />
      </Box>

      <Box className={classes.categories}>
        {!isTradeTestBooking && provisionalProfessional && <>
          <Typography variant='overline'>{s("$0's treatments", provisionalProfessional.firstName.trim())}</Typography>
        </>}

        {!isTradeTestBooking && categories.map((item: $CategoryModel) => {
          const { data, urlSafeName } = item
          const categoryTheme = categoryThemes[data.slug] || categoryThemes.default

          if (!categoryTheme) return null

          return (<Box key={data.name}>
            <NavMenuLink
              title={data.name}
              icon={categoryTheme.icon}
              href={'/categories/[id]'}
              as={`/categories/${urlSafeName}`}
            />
            <Box mt={1} />
          </Box>)
        })}

        {provisionalProfessional &&
          <>
            <Box mt={2} mb={3}>
              <Divider light />
            </Box>
            <TextButton onClick={showRemoveProfessionalPopup}>{s('View all treatments')}</TextButton>
          </>
        }

        <Box mt={3} mb={3}>
          <Divider light />
        </Box>

        <NavMenuLink
          title={'Gift Card'}
          icon={GiftCardIcon}
          href={'#giftCard'}
        />

        <NavMenuLink
          title={'Contact us'}
          icon={ContactIcon}
          href={'#support'}
        />
      </Box>
    </Box>

    if (drawer) {
      return <SwipeableDrawer
        anchor={'left'}
        open={navVisible}
        onClose={() => toggleNavVisible(false)}
        onOpen={() => toggleNavVisible(true)}
      >
        <Box className={classes.drawer}>
          {content}
        </Box>
      </SwipeableDrawer>
    }

    return <Box className={`hideScrollbars ${classes.sideMenu}`}>
      {content}
    </Box>
  }
}

export default withStyles(styles)(NavMenu)
