import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Box, Typography } from '@material-ui/core'
import { currencyFormat } from '../../shared-lib/currency'
import { itemDescription, itemPrice } from '../../shared-consumer/helpers/bookingItemHelpers'
import { $Treatment } from '../../shared-consumer/stores/TreatmentStore/TreatmentModel'
import RootStore from '../../src/stores/RootStore'
import { ELITE_TREATMENT_COST, OFFPEAK_ITEM_NAME, SERVICE_FEE_ITEM_NAME } from '../../shared-lib/commonStrings'
import InfoIconButton from '../InfoIconButton'
import CustomDivider from '../CustomDivider'
import RectButton from '../RectButton'
import { Cancel } from '@material-ui/icons'

interface Props {
  store?: RootStore
  canEdit: boolean
}

@inject('store')
@observer
class ItemSummary extends Component<Props> {
  showServiceFeeInfo = () => {
    const { uiStore, i18n: { s } } = this.props.store!

    uiStore.showInformationModal({
      title: s('Service Fee'),
      body: s('SERVICE_FEE_EXPLAINER'),
    })
  }

  render() {
    const { store, canEdit } = this.props
    const { booking: { data: { items } }, removeItem } = store!.checkoutStore
    const { i18n: { s } } = store!

    return (
      <Box aria-label={'cart_items'}>
        {
          items.map((item: { _id: string, treatment: $Treatment }, index: number) => {
            const { name } = item.treatment
            const isServiceFee = name === SERVICE_FEE_ITEM_NAME
            const isEliteFee = name === ELITE_TREATMENT_COST
            const isRemovable = !isServiceFee && name !== OFFPEAK_ITEM_NAME &&
              !isEliteFee

            return <Box key={index}>
              <Box mt={3} key={item._id} display='flex' flexDirection='row' justifyContent='space-between'>
                <Box className='animate__animated animate__fadeIn'>
                  <Box display='flex'>
                    <Typography variant='body2'>{isEliteFee ? s(name) : name}</Typography>

                    {/* AB Test: Service Fees */}
                    {isServiceFee &&
                      <InfoIconButton onClick={this.showServiceFeeInfo} />
                    }
                  </Box>

                  <Typography variant='body1'>{itemDescription(item.treatment)}</Typography>

                  {canEdit && isRemovable && <Box mt={1}>
                    <RectButton
                      variant='text'
                      onClick={() => removeItem(item)}
                      startIcon={<Cancel />}
                    >
                      {s('Remove')}
                    </RectButton>
                  </Box>}
                </Box>

                <Box pl={2}>
                  <Typography variant='body2'>{currencyFormat(itemPrice(item.treatment))}</Typography>
                </Box>
              </Box>

              <Box mt={3} mb={3}>
                <CustomDivider />
              </Box>
            </Box>
          })
        }
      </Box>
    )
  }
}

export default ItemSummary
