import { Divider } from '@material-ui/core'
import React from 'react'

import { makeStyles } from '@material-ui/core'
import { styleCreate } from '../lib/styleCreate'
import { colors } from '../theme'

interface Props {
  minusSpacing?: number
  color?: 'black'
  width?: string
}

const useStyles = makeStyles(styleCreate(theme => ({
  divider: {
    marginLeft: (props: Props) => theme.spacing(-props.minusSpacing),
    marginRight: (props: Props) => theme.spacing(-props.minusSpacing),
    backgroundColor: (props: Props) => props.color === 'black' ? colors.BLACK900 : undefined,
    width: (props: Props) => props.width,
  },
})))

const CustomDivider = ({ minusSpacing = 4, ...props }: Props) => {
  const classes = useStyles({ minusSpacing, ...props })

  return <Divider className={classes.divider} />
}

export default CustomDivider