import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { Box, Button, WithStyles, withStyles } from '@material-ui/core'
import { Menu } from '@material-ui/icons'

import RootStore from '../src/stores/RootStore'
import { colors } from '../theme/'
import { Logo } from './'
import ZendeskHelpButton from './ZendeskHelpButton'
import { styleCreate } from '../lib/styleCreate'

interface Props extends WithStyles<typeof styles> {
  store?: RootStore
}

const styles = styleCreate(theme => ({
  mobileToolbar: {
    position: 'fixed',
    width: '100vw',
    top: 0,
    height: theme.spacing(10),
    backgroundColor: colors.BLACK900,
    zIndex: 100,
  },
}))

@inject('store')
@observer
class MobileToolbar extends Component<Props> {
  render() {
    const { store, classes } = this.props
    const { uiStore: { toggleNavVisible }, chatStore: { unreadMessages } } = store!

    return (
      <Box className={classes.mobileToolbar} flexDirection='row' display='flex' justifyContent='space-between' alignItems='center'>
        <Button aria-label='mobile-menu-button' onClick={() => { toggleNavVisible(true) }}>
          {!!unreadMessages && <Box width={10} height={10} borderRadius={10} style={{ backgroundColor: colors.RED400 }} position='absolute' right={18} top={8} />}
          <Menu color='secondary' />
        </Button>

        <Logo size={16} inverted />

        <ZendeskHelpButton color='secondary' />
      </Box >
    )
  }
}

export default withStyles(styles)(MobileToolbar)
